<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-row>
          <b-col>
            <b-card>
              <b-card-header
                :class="{ 'border-bottom': participants.length > 1}"
                class="d-flex justify-items-center p-0"
              >
                <div class="d-flex align-items-center  p-0">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    @click="$router.back()"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="ChevronLeftIcon"
                    />
                  </b-button>
                  <h3 class="mb-0 ml-2">
                    {{ title }}
                  </h3>
                </div>
                <div>
                  <b-badge
                    class="ml-1"
                    size="lg"
                    style="font-size: 1.5rem; padding: 0.5rem 1rem;"
                    variant="primary"
                  >
                    {{ programDetails.title }}
                  </b-badge>
                </div>
              </b-card-header>
              <b-row
                v-if="participants.length > 1"
              >
                <b-col
                  cols="12"
                >
                  <b-form-group
                    :label-class="`h5`"
                    label="Select Participant"
                  >
                    <v-select
                      id="selectParticipant"
                      v-model="selectOrg"
                      :get-option-label="option => option.programs_applicantstable.users_organizationtable.title"
                      :options="participants"
                      :reduce="participant => participant.id"
                      placeholder="Choose one from the list"
                      @input="$router.replace({name: $route.name,
                                               params: {pid: $route.params.pid,
                                                        apid: $route.params.apid,
                                                        aid: selectOrg}})"
                    />
                  </b-form-group>
                  <hr>
                </b-col>
              </b-row>
            </b-card>
            <form-wizard
              :hide-buttons="$route.name === 'startup-review-questions' && !selectOrg"
              :subtitle="null"
              :title="null"
              class="mb-3"
              color="#7367F0"
              shape="square"
              @on-complete="updateAnswer"
            >
              <template #footer="props">
                <div class="wizard-footer-left">
                  <wizard-button
                    v-if="props.activeTabIndex > 0 && !props.isLastStep"
                    :style="props.fillButtonStyle"
                  >
                    Previous
                  </wizard-button>
                </div>
                <div class="wizard-footer-right">
                  <wizard-button
                    v-if="!props.isLastStep"
                    class="wizard-footer-right"
                    :style="props.fillButtonStyle"
                    @click.native="props.nextTab()"
                  >
                    Next
                  </wizard-button>

                  <wizard-button
                    v-else-if="!readonly"
                    class="wizard-footer-right finish-button"
                    :style="props.fillButtonStyle"
                    @click.native="updateAnswer"
                  >
                    Submit
                  </wizard-button>
                </div>
              </template>
              <tab-content
                v-for="(section, i) in questions"
                :key="i"
                :title="i"
              >
                <b-row
                  v-for="(question, index) in section"
                  :key="index"
                >
                  <b-col>
                    <b-form-group
                      :label="`Q. ${question.question}`"
                      :label-class="`h5`"
                      :label-for="`question-${index}`"
                      class="mb-2"
                    >
                      <b-input-group class="mt-50">
                        <b-form-textarea
                          v-if="question.input_type === 'Long text'"
                          :id="`answer-${index}`"
                          v-model="question.programs_startupresponsetables[0].answer"
                          :readonly="readonly"
                          placeholder="Your answer"
                          required
                        />
                        <v-select
                          v-else-if="question.input_type === 'Dropdown' || question.input_type === 'Multiselect'"
                          :id="`answer-${index}`"
                          v-model="question.programs_startupresponsetables[0].answer"
                          :multiple="question.input_type === 'Multiselect'"
                          :options="JSON.parse(question.options)"
                          :style="readonly?'pointer-events:none;filter:grayscale(100%)':''"
                          append-to-body
                          class="w-100"
                          label="Choose from the following"
                          placeholder="Select from list"
                        />
                        <Promised
                          v-else-if="question.input_type==='Document' && question.programs_startupresponsetables[0].answer"
                          v-once
                          :promise="getLinkFromS3(question.programs_startupresponsetables[0].answer)"
                        >
                          <template #pending>
                            <b-button
                              disabled
                              size="sm"
                              variant="outline-primary"
                            >
                              <feather-icon icon="ClockIcon" />
                            </b-button>
                          </template>
                          <template #default="data">
                            <div>
                              <b-button
                                :href="data"
                                size="sm"
                                target="_blank"
                                variant="outline-primary"
                              >View Uploaded File
                              </b-button>
                            </div>
                          </template>
                          <template #rejected>
                            <span v-b-tooltip="`File not found`">
                              <b-button

                                disabled
                                size="sm"
                                variant="outline-danger"
                              ><feather-icon icon="AlertTriangleIcon" /></b-button>
                            </span>
                          </template>
                        </Promised>
                        <b-form-input
                          v-else
                          :id="`answer-${index}`"
                          v-model="question.programs_startupresponsetables[0].answer"
                          :readonly="readonly"
                          :type="question.input_type === 'Number' ? 'number' : 'text'"
                          placeholder="Your answer"
                          required
                        />

                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </tab-content>
            </form-wizard>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
// eslint-disable-next-line
import {
  BButton, BCard, BCardHeader, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { Promised } from 'vue-promised'
import { mapGetters } from 'vuex'

export default {
  components: {
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BCard,
    BFormTextarea,
    BCardHeader,
    FormWizard,
    TabContent,
    Promised,
  },
  data() {
    return {
      programDetails: [],
      exerciseData: [],
      selectOrg: Number.parseInt(this.$route.params.aid, 10),
      mutationLoading: false,
      status: 'disabled',
      title: '',
      participants: [],
    }
  },
  computed: {
    ...mapGetters({
      selectedOrgDetails: 'user/getSelectedOrgDetails',
    }),
    readonly() {
      return !this.$route.params.aid || this.status !== 'enabled' || this.$route.query.readonly === 'true'
    },
    questions() {
      this.exerciseData.forEach(el => {
        if (el.programs_startupresponsetables.length === 0) {
          el.programs_startupresponsetables.push({
            answer: '',
            participant_id: this.$route.params.aid || 0,
          })
        }
      })
      return this.groupByKey(this.exerciseData, 'section')
    },
    answers() {
      return this.exerciseData.map(question => ({
        assignment_question_id: question.id,
        participant_id: this.$route.params.aid,
        ...question.programs_startupresponsetables[0],
        answer: JSON.stringify(question.programs_startupresponsetables[0].answer),
      }))
    },
  },
  methods: {
    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    updateAnswer() {
      if (this.readonly) {
        return this.showAlert('Submission is disabled', 'warning')
      }

      const hasEmptyAnswers = this.answers.some(ans => !ans.answer)

      if (hasEmptyAnswers) {
        return this.showAlert('Fields are empty', 'danger')
      }

      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_startupresponsetable_insert_input!]!) {
          insert_programs_startupresponsetable(objects: $answers, on_conflict: {constraint: programs_responsetable_pkey, update_columns: answer}) {
            affected_rows
          }
        }`,
        variables: {
          answers: this.answers,
        },
        update: (_store, { data: { insert_programs_startupresponsetable } }) => {
          this.mutationLoading = false
          if (insert_programs_startupresponsetable.affected_rows) return this.showAlert('Submission successful', 'success')
          return this.showAlert('Submission failed', 'danger')
        },
      })

      return true
    },
  },
  apollo: {
    exerciseData: {
      query() {
        return gql`
      query GetExerciseData($apid: Int!, $participantId: Int!) {
        programs_assignmenttable_by_pk(id: $apid) {
          id
          status
          title
          programs_assignmentquestionstables(order_by: {id: asc}) {
            id
            programs_startupresponsetables(where: {participant_id: {_eq: $participantId}}) {
              id
              answer
              participant_id
            }
            section
            question
            options
            input_type
          }
        }
      }
    `
      },
      variables() {
        return {
          apid: this.$route.params.apid,
          participantId: this.$route.params.aid,
        }
      },
      update(data) {
        this.status = data.programs_assignmenttable_by_pk.status
        this.title = data.programs_assignmenttable_by_pk.title
        if (data.programs_assignmenttable_by_pk) {
          data.programs_assignmenttable_by_pk.programs_assignmentquestionstables.forEach(q => {
            q.programs_startupresponsetables.map(a => {
              try {
                JSON.parse(a.answer)
              } catch (e) {
                return a
              }
              return Object.assign(a, { answer: JSON.parse(a.answer) })
            })
          })
        }
        return data.programs_assignmenttable_by_pk.programs_assignmentquestionstables
      },
    },
    participants: {
      query() {
        return gql`
    query StartupParticipantsByAssignment($apid: Int!, $orgId: Int!) {
      programs_startupparticipants(
        where: {
          programs_applicantstable: {
            programs_basicinfo: {
              programs_operationstables: {
                programs_assignmenttables: { id: { _eq: $apid } }
              }
            },
            organization_id_id: { _eq: $orgId }
          }
        }
      ) {
        id
        programs_applicantstable {
          users_organizationtable {
            title
          }
        }
      }
    }
  `
      },
      variables() {
        return {
          apid: this.$route.params.apid,
          orgId: this.selectedOrgDetails.orgId,
        }
      },
      update(data) {
        if (!data.programs_startupparticipants.length) return []
        return data.programs_startupparticipants
      },
    },
    programDetails: {
      query() {
        return gql`query programsDetails {
            programs_basicinfo(where: {id: {_eq: ${this.$route.params.pid}}}) {
              id
              title
            }
          }`
      },
      update: data => data.programs_basicinfo[0],
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
